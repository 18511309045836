import { PaginationParams, ServerPageData } from '@mobilizeyourtech/vision-core-react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore } from 'stores'
import classnames from 'classnames'

import {
  AutoCompleteItem,
  ServerBackedAutoComplete,
  ServerBackedAutoCompleteProps,
} from 'components/custom/ServerBackedAutoComplete/ServerBackedAutoComplete'
import { GovUserOrganizationResponse } from 'stores/ServerStore'
import { GovUserOrganizationParams } from 'lib/types/Params'

export interface GovUserOrganizationAutoCompleteInputProps
  extends Omit<
    ServerBackedAutoCompleteProps<GovUserOrganizationResponse & AutoCompleteItem>,
    'getDataFunction'
  > {
  getOrganizations: (
    params: GovUserOrganizationParams,
  ) => Promise<ServerPageData<GovUserOrganizationResponse>>
  notificationStore?: NotificationStore
}

export const GovUserOrganizationAutoCompleteInput = inject(
  ApplicationStore.names.notificationStore,
)(
  observer(
    ({
      getOrganizations,
      notificationStore: _notificationStore,
      ...props
    }: GovUserOrganizationAutoCompleteInputProps) => {
      const loadOrganizations = (p: PaginationParams & { ids?: string }) => {
        return getOrganizations(p).then((res) => {
          return {
            data: res.data.map((i) => {
              return { ...i, label: i.name, categoryLabel: i.category }
            }),
            pagination: res.pagination,
          }
        })
      }

      return (
        <ServerBackedAutoComplete
          {...props}
          className={classnames(props.className)}
          getDataFunction={loadOrganizations}
          testId="-organization"
          selected={
            props.selected
              ? {
                  ...props.selected,
                  label: props.selected.longName,
                  shortLabel: props.selected.shortName,
                }
              : undefined
          }
        />
      )
    },
  ),
)
