import React from 'react'
import { CCol, CFormLabel, CRow } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { GovUserExtendedDataFormData } from './types'
import { GovUserOrganizationAutoCompleteInput } from 'components/Molecules/AutoCompleteInputs/GovUserOrganizationAutoCompleteInput/GovUserOrganizationAutoCompleteInput'

export interface GovUserExtendedDataOrganizationFormProps {
  value: Partial<GovUserExtendedDataFormData>
  onChange: (value: Partial<GovUserExtendedDataFormData>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}
export const GovUserExtendedDataOrganizationForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: GovUserExtendedDataOrganizationFormProps) => {
    return (
      <>
        <CRow className="mb-3">
          <CFormLabel
            {...{ htmlFor: 'signupOrganizationName' }}
            className="col-sm-2 col-form-label d-flex justify-content-between"
          >
            <span>Organization Name</span>
          </CFormLabel>
          <CCol>
            <GovUserOrganizationAutoCompleteInput
              placeholder="Organization"
              getOrganizations={(p) => {
                return props.serverStore!.getGovUserOrganizations({
                  ...p,
                  eager: 'category',
                  sort: 'category',
                })
              }}
              selected={props.value.govUserOrganization}
              onSelect={(value) => {
                props.onChange({
                  govUserOrganization: value,
                })
              }}
              required
            />
          </CCol>
        </CRow>
      </>
    )
  }),
)
