import { CCard, CCardBody, CCardText, CTooltip } from '@coreui/react'
import { inject, observer } from 'mobx-react'
import { AccountStore, NotificationStore, ApplicationStore } from '../../../stores'
import { GovUserData } from '../../../stores/UserStore'
import { visionVisibilityIcon } from '../../../assets/brand/vision_visibility_icon'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import { UserAvatar } from '../../Molecules/Avatars/UserAvatar/UserAvatar'

type TProps = {
  accountStore?: AccountStore
  notificationStore?: NotificationStore
  user: GovUserData
}

export const GovUserDetailCard = inject(
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.accountStore,
)(
  observer((props: TProps) => {
    const avatar = () => {
      return (
        <div className="avatar-container d-flex justify-content-center align-items-center">
          <UserAvatar className="gov-user-card-avatar" user={props.user} displayIsActive />
        </div>
      )
    }

    const userInfo = () => {
      const currentUserAccountChatThreadId =
        props.user.chatThreadIdsWithCurrentUserAccount &&
        props.user.chatThreadIdsWithCurrentUserAccount.length > 0 &&
        props.user.chatThreadIdsWithCurrentUserAccount[0]
      return (
        <div className="user-info-container" data-testid="user-info-container">
          <div
            className={
              'gov-user-name pt-3 pb-1 d-flex justify-content-center align-items-center gap-2'
            }
          >
            <h5 className="text-truncate mb-0">
              {props.user.firstName} {props.user.lastName}
            </h5>
            {props.user.govUserExtendedData.visionUserId && (
              <CTooltip content={'Verified VISION user'}>
                <div className="d-flex justify-content-center align-items-center">
                  {visionVisibilityIcon()}
                </div>
              </CTooltip>
            )}
            {currentUserAccountChatThreadId && (
              <Link
                data-testid="current-user-account-chat-link"
                className="chat-link clickable"
                to={`/chat?threadId=${currentUserAccountChatThreadId}`}
                title="Go to chat"
              >
                <i className="fas fa-comment chat-icon" />
              </Link>
            )}
          </div>

          <CCardText className="gov-user-title pb-1 text-truncate">{props.user.title}</CCardText>
          <CCardText className="gov-user-title pb-1 text-truncate">
            {props.user.govUserExtendedData.govUserOrganization.longName}
          </CCardText>
        </div>
      )
    }

    const numTechTypesShown: number = 1

    const userTechnologyTypes = () => {
      let techTypeNames = props.user.technologyTypes
        ?.slice(0, numTechTypesShown)
        .map((technologyType) => {
          return technologyType.name
        })

      return (
        <div className="gov-user-tech-types">
          <p className="ms-auto subtle">
            {techTypeNames.join(', ')}
            {techTypesNotShown()}
          </p>
        </div>
      )
    }

    const techTypesNotShown = (): string => {
      if (!props.user.technologyTypes) return ''
      let techTypesNotShownCount = props.user.technologyTypes.length - numTechTypesShown

      if (techTypesNotShownCount > 0) {
        return ` and ${techTypesNotShownCount} other(s)`
      }

      return ''
    }

    const renderCardContent = () => {
      let content
      if (props.user.searchMetadata?.highlight) {
        content = ReactHtmlParser(props.user.searchMetadata.highlight)
      } else {
        content = ReactHtmlParser(props.user.govUserExtendedData.profileDetail)
      }

      return (
        <div className="line-clamp" data-testid="detail-content">
          {content}
        </div>
      )
    }

    return (
      <CCard
        data-testid={`gov-user-detail-card-${props.user.email}`}
        className="GovUserDetailCard d-flex flex-column justify-content-between text-center py-3"
      >
        <CCardBody>
          {avatar()}
          {userInfo()}
          {renderCardContent()}
          {userTechnologyTypes()}
        </CCardBody>
      </CCard>
    )
  }),
)
