import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { ApplicationStore, UserStore, AuthStore, NotificationStore } from '../stores'
import {
  CustomModal,
  SuspensefulButton,
  SuspensionBoundary,
} from '@mobilizeyourtech/vision-core-react'
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CRow,
  CLink,
  CFormCheck,
  CModalBody,
} from '@coreui/react'
import { Link, Redirect } from 'react-router-dom'
import {
  SignupLoginData,
  SignupLoginForm,
  SignupUserData,
  SignupUserForm,
  GovUserExtendedDataOrganizationForm,
} from '../components/form/fragments'
import {
  validateLength,
  validateLineOfEffort,
  validatePassword,
  validateValueMatch,
} from '../lib/helpers/validation'
import { leftArrow } from '../components/icons/leftArrow'
import ReCAPTCHA from 'react-google-recaptcha'
import { GovUserExtendedDataProfileForm } from '../components/form/fragments/GovUserExtendedDataProfileForm'
import { GovUserExtendedDataFormData } from '../components/form/fragments/types'
import { extractRestfulError } from '../lib/errors/utils'
import { visionLogoCobalt } from '../assets/brand/visionLogoCobalt'
import { clearQParamSessionVariables, getSessionQParam } from '../lib/helpers/sessionHelpers'

type GovUserFormData = SignupLoginData & SignupUserData

interface GovUserSignupProps {
  userStore?: UserStore
  authStore?: AuthStore
  notificationStore?: NotificationStore
  applicationStore?: ApplicationStore
}

export const GovUserSignup = inject(
  ApplicationStore.names.authStore,
  ApplicationStore.names.notificationStore,
  ApplicationStore.names.userStore,
  'applicationStore',
)(
  observer((props: GovUserSignupProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [govUserSignupData, setGovUserSignupData] = useState<Partial<GovUserFormData>>({})
    const [govUserExtendedData, setGovUserExtendedData] = useState<
      Partial<GovUserExtendedDataFormData>
    >({
      linesOfEffort: [{ details: '' }],
    })
    const [hasValidated, setHasValidated] = useState<boolean>(false)
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<string | undefined>(undefined)
    const [connectVisionAccountHelpModalVisible, setConnectVisionAccountHelpModalVisible] =
      useState<boolean>(false)
    const [unclassifiedConfirmation, setUnclassifiedCofirmation] = useState(false)
    const [spamProtectionModalVisible, setSpamProtectionModalVisible] = useState(false)

    const decryptGovUserData = (params: string) => {
      // The user is coming from VISION
      props.userStore
        ?.decryptAndValidateGovUserData(params)
        .then((decryptedGovUserData) => {
          setGovUserSignupData({ ...decryptedGovUserData, title: decryptedGovUserData.position! })
        })
        .catch((error) => {
          let message = extractRestfulError(error)?.error
          if (message === 'UserAlreadyExists') {
            props.notificationStore?.setNotificationMessage(
              'A User with your Vision email already exists. Please log in.',
              'info',
              5000,
              true,
            )
          }
          clearQParamSessionVariables()
          setRedirect('/')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    useEffect(() => {
      setIsLoading(true)
      const q = getSessionQParam()
      // Intentionally having this consider a blank string as presence
      if (q?.trim() === '') {
        setIsLoading(false)
        setRedirect('/')
      } else if (q) {
        decryptGovUserData(q)
      } else {
        // The user is a non-VISION gov user
        setIsLoading(false)
      }
    }, [])

    const isSubmittable = (): boolean => {
      if (!govUserSignupData.emailValid) {
        return false
      }

      if (!govUserExtendedData?.technologyTypes || govUserExtendedData.technologyTypes.length < 1) {
        return false
      }

      if (
        !govUserExtendedData?.govUserClassifications ||
        govUserExtendedData.govUserClassifications.length < 1
      ) {
        return false
      }

      if (!govUserExtendedData.linesOfEffort || govUserExtendedData.linesOfEffort.length < 1) {
        return false
      }

      let errors = [
        validatePassword(govUserSignupData.password),
        validateValueMatch(govUserSignupData.password, govUserSignupData.confirmPassword),
        validateLength(govUserSignupData.firstName, 2),
        validateLength(govUserSignupData.lastName, 2),
        validateLength(govUserSignupData.title, 2),
        validateLength(govUserExtendedData.profileDetail?.replace(/<[^>]*>/g, ''), 2),
        validateLineOfEffort(govUserExtendedData.linesOfEffort),
      ]

      if (errors.some((err) => !!err)) {
        return false
      }

      let presence = [
        govUserExtendedData.govUserOrganization?.id,
        govUserSignupData.timezone,
        unclassifiedConfirmation,
        recaptchaToken,
      ].every((e) => !!e)

      return presence
    }

    const onSubmit = () => {
      if (!isSubmittable()) {
        return
      }

      setHasValidated(true)
      setIsSubmitting(true)

      props
        .userStore!.govUserSignUp({
          user: {
            password: govUserSignupData.password!,
            confirmPassword: govUserSignupData.confirmPassword!,
            firstName: govUserSignupData.firstName!,
            lastName: govUserSignupData.lastName!,
            email: govUserSignupData.email!,
            title: govUserSignupData.title!,
            timezone:
              typeof govUserSignupData.timezone === 'string'
                ? govUserSignupData.timezone
                : govUserSignupData.timezone!.value,
            headshot: govUserSignupData.headshot,
            encryptedVisionUserData: getSessionQParam() || undefined,
            govUserOrganizationId: govUserExtendedData.govUserOrganization!.id,
            technologyTypes: govUserExtendedData.technologyTypes,
            profileDetail: govUserExtendedData.profileDetail,
            interactionDetail: govUserExtendedData.interactionDetail,
            linesOfEffort: govUserExtendedData?.linesOfEffort?.filter(
              (el) => el.details && el.details.trim() !== '',
            ),
            govUserClassifications: govUserExtendedData.govUserClassifications,
            city: govUserExtendedData.location?.city,
            stateOrProvince: govUserExtendedData.location?.stateOrProvince,
            country: govUserExtendedData.location?.country,
            govEmployeeSinceYear: govUserExtendedData.govEmployeeSinceYear,
          },
          recaptchaToken: recaptchaToken!,
        })
        .then(({ authorization, ...userdata }) => {
          setIsSubmitting(false)

          props.notificationStore?.setNotificationMessage(
            'Account successfully created!',
            'success',
            3000,
            true,
          )
          props.authStore!.setJWT(authorization)
          props.authStore!.generateAndSetSalt()
        })
        .catch(() => {
          setIsSubmitting(false)
          props.notificationStore?.setNotificationMessage(
            'Something went wrong, please try again',
            'danger',
            3000,
          )
        })
    }

    if (redirect) {
      return <Redirect to={redirect} />
    }

    return (
      <section className="GovUserSignup">
        <SuspensionBoundary isLoading={isLoading}>
          <CustomModal
            className="GovUserSignupModal"
            isModalOpen={connectVisionAccountHelpModalVisible}
            onClose={() => setConnectVisionAccountHelpModalVisible(false)}
          >
            <>
              <div className="d-flex px-4 pb-4">
                <div className="vision-logo-md" data-testid="vision-logo-md">
                  {visionLogoCobalt()}
                </div>
                <h4 className="mt-1 px-3 flex-grow-1">Connecting your VISION account to IGNITE</h4>
              </div>
              <ol className="ol-inside-modal-body">
                <li>
                  <CLink
                    className="clickable"
                    href={`${props.applicationStore?.siteSettings?.visionUrl}/ignite/connect`}
                  >
                    Click here
                  </CLink>{' '}
                  to access IGNITE within VISION (you will be navigated to the IGNITE search page
                  within VISION)
                </li>
                <li>On the IGNITE search page, click the &quot;View in IGNITE&quot; button</li>
                <li>
                  After clicking the &quot;View in IGNITE&quot; button you will be navigated back to
                  this page with your VISION account information populated and ready to be linked to
                  your new IGNITE account
                </li>
              </ol>
            </>
          </CustomModal>
          <CForm validated={hasValidated} className="needs-validation" {...{ noValidate: true }}>
            <CContainer>
              <CCard>
                <CCardBody>
                  <div className="bounded-container d-flex align-items-center">
                    <Link
                      className="nav-back clickable"
                      data-testid="nav-back"
                      aria-label="navigate back to signin"
                      tabIndex={0}
                      to="/"
                    >
                      {leftArrow()}
                    </Link>
                    <h5>Sign Up</h5>
                    {/* TODO put this chip/card into a component/atom when time allows */}
                    {getSessionQParam() ? (
                      <div className="d-flex flex-grow-1 justify-content-end">
                        <div className="d-flex align-items-center border border-success rounded p-2 bg-light">
                          <div className="vision-logo-sm d-flex align-items-center">
                            {visionLogoCobalt()}
                          </div>
                          <div className="px-2">
                            Your VISION account will be linked to your new IGNITE account
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-grow-1 justify-content-end">
                        <div
                          onClick={() => setConnectVisionAccountHelpModalVisible(true)}
                          className="clickable d-flex align-items-center border border-secondary rounded p-2 bg-light"
                          data-testid="connect-vision-account-card"
                        >
                          <div className="vision-logo-sm d-flex align-items-center">
                            {visionLogoCobalt()}
                          </div>
                          <div className="bold-label-600 px-2">VISION User?</div>
                          <span>
                            <u>Connect your VISION account to IGNITE</u>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <CRow>
                    <CCol>
                      <div className="bounded-container">
                        <h6>
                          <b>Your Login</b>
                        </h6>
                        <SignupLoginForm
                          value={govUserSignupData}
                          onChange={(value) =>
                            setGovUserSignupData((prev) => ({ ...prev, ...value }))
                          }
                          disableEmail={!!getSessionQParam()}
                          isVisionUser={!!getSessionQParam()}
                          openConnectVisionAccountModal={() => {
                            setConnectVisionAccountHelpModalVisible(true)
                          }}
                          visionAccountConnected={!!getSessionQParam()}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="bounded-container">
                        <h6>
                          <b>Your Organization</b>
                        </h6>
                        <GovUserExtendedDataOrganizationForm
                          value={govUserExtendedData}
                          onChange={(value) => {
                            setGovUserExtendedData((prev) => ({ ...prev, ...value }))
                          }}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="bounded-container">
                        <h6>
                          <b>Your User</b>
                        </h6>
                        <SignupUserForm
                          value={govUserSignupData}
                          onChange={(value) =>
                            setGovUserSignupData((prev) => ({ ...prev, ...value }))
                          }
                          isGovUser={true}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div className="bounded-container">
                        <CustomModal
                          isModalOpen={spamProtectionModalVisible}
                          onClose={() => setSpamProtectionModalVisible(false)}
                          headerContent={
                            <h4 className={'mb-0'}>How IGNITE protects you from spam</h4>
                          }
                        >
                          <CModalBody className={'pt-0'}>
                            <CRow className={'d-flex gap-2'}>
                              <CCol className={'col-12'}>
                                <CRow className={'d-flex'}>
                                  <CCol className={'col-1'}>
                                    <i
                                      className={'fa fa-regular fa-comment primary-accent-label'}
                                    />
                                  </CCol>
                                  <CCol className={'col-11'}>
                                    <span>You can disable new communications at any time.</span>
                                  </CCol>
                                </CRow>
                              </CCol>
                              <CCol className={'col-12'}>
                                <CRow>
                                  <CCol className={'col-1'}>
                                    <i
                                      className={'fa fa-regular fa-shield-alt primary-accent-label'}
                                    />
                                  </CCol>
                                  <CCol className={'col-11'}>
                                    <span>
                                      Small businesses can only send 1 message to you to initiate a
                                      conversation. They can not send more messages until you have
                                      replied to the first one.
                                    </span>
                                  </CCol>
                                </CRow>
                              </CCol>
                              <CCol className={'col-12'}>
                                <CRow>
                                  <CCol className={'col-1'}>
                                    <i className={'fa fa-regular fa-lock primary-accent-label'} />
                                  </CCol>
                                  <CCol className={'col-11'}>
                                    <span>
                                      At any time, you can pause communications with a small
                                      business. They will be unable to contact you until you
                                      un-pause.
                                    </span>
                                  </CCol>
                                </CRow>
                              </CCol>
                              <CCol className={'col-12'}>
                                <CRow>
                                  <CCol className={'col-1'}>
                                    <i
                                      className={
                                        'fa fa-regular fa-user-shield primary-accent-label'
                                      }
                                    />
                                  </CCol>
                                  <CCol className={'col-11'}>
                                    <span>
                                      You have control of the conversation. If you feel like someone
                                      is misusing the system and spamming you, contact{' '}
                                      <a
                                        href={'mailto:support@mobilizeignite.com'}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        support@mobilizeignite.com
                                      </a>{' '}
                                      and our team will address the issue.
                                    </span>
                                  </CCol>
                                </CRow>
                              </CCol>
                            </CRow>
                          </CModalBody>
                        </CustomModal>
                        <h6>
                          <b>Your Profile</b>
                        </h6>
                        <div className={'mb-2'}>
                          <span>
                            Important: Your profile information is how people will find in you in
                            IGNITE. The more thorough your answers, the better your chances of
                            making connections.
                          </span>
                        </div>
                        <div className="d-flex flex-grow-1 justify-content-start mb-4">
                          <div
                            onClick={() => setSpamProtectionModalVisible(true)}
                            className="clickable d-flex align-items-center border border-secondary rounded p-2 bg-light"
                            data-testid="spam-protection-card"
                          >
                            <i
                              className={'fa fa-regular fa-shield-alt me-1 primary-accent-label'}
                            />
                            <span>
                              <u>How IGNITE protects you from spam</u>
                            </span>
                          </div>
                        </div>
                        <GovUserExtendedDataProfileForm
                          value={govUserExtendedData}
                          onChange={(value) =>
                            setGovUserExtendedData((prev) => ({ ...prev, ...value }))
                          }
                        />
                        <div className={'mb-2'}>
                          <CFormCheck
                            data-testid="unclassified-confirmation-checkbox"
                            onChange={() => setUnclassifiedCofirmation(!unclassifiedConfirmation)}
                            checked={unclassifiedConfirmation}
                            label={
                              <div>
                                <div>
                                  I affirm that all information I have inputted is unclassified.{' '}
                                  <i className="fas fa-asterisk gov-required-icon" />
                                  (required)
                                </div>
                                <em>
                                  IGNITE handles information at a non-CUI level. Do not add any
                                  information or upload any documents that are classified or
                                  Controlled Unclassified Information (CUI)
                                </em>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <CContainer className="recaptcha-container">
                    <ReCAPTCHA
                      sitekey={props.applicationStore!.siteSettings!['recaptchaSiteKey']}
                      onChange={(token) => setRecaptchaToken(token)}
                      data-testid="recaptcha-checkbox"
                    />
                  </CContainer>
                  <CRow className="justify-content-md-center">
                    <CCol sm={2} className="d-flex justify-content-md-center">
                      <SuspensefulButton
                        isSuspended={isSubmitting}
                        onClick={onSubmit}
                        disabled={!isSubmittable() || isSubmitting}
                        data-testid="signup-button"
                        icon="fas fa-check"
                      >
                        Sign Up
                      </SuspensefulButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CContainer>
          </CForm>
        </SuspensionBoundary>
      </section>
    )
  }),
)
