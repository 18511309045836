import { CCol, CFormLabel, CRow, CFormFeedback } from '@coreui/react'
import {
  validateEmail,
  validatePassword,
  validateValueMatch,
} from '../../../lib/helpers/validation'
import { AsyncValidatedTextInput, IconPopover, ValidatedCFormInput } from '../../custom'
import { inject, observer } from 'mobx-react'
import { ApplicationStore } from '../../../stores'

export type SignupLoginData = {
  email: string
  password: string
  confirmPassword: string
  emailValid: boolean
}

type TProps = {
  value: Partial<SignupLoginData>
  onChange: (value: Partial<SignupLoginData>) => void
  isEmailHidden?: boolean
  disableEmail?: boolean
  isVisionUser?: boolean
  applicationStore?: ApplicationStore
  openConnectVisionAccountModal?: () => void
  visionAccountConnected?: boolean
}

export const SignupLoginForm = inject('applicationStore')(
  observer((props: TProps) => {
    const onEmailChange = async (email: string | undefined) => {
      if (!email) {
        return props.onChange({ email, emailValid: false })
      }

      const emailValidReason = await asyncValidateEmail(email)
      return props.onChange({ email, emailValid: emailValidReason == null })
    }

    const asyncValidateEmail = async (email: string) => {
      if (props.disableEmail) {
        return
      }

      const syncValidation = validateEmail(email)
      if (syncValidation) {
        return syncValidation
      }

      if (!props.isVisionUser) {
        const { value } = await props.applicationStore!.checkSignupUserEmail(email)
        if (!value) {
          return 'disallowed_email'
        }
      }
    }

    const makeEmailValidationMessage = (reason: string): string => {
      if (reason === 'invalid_email') {
        return 'Provide a valid email'
      } else if (reason === 'disallowed_email') {
        return 'This email is not currently allowed for Ignite government user signup. Please contact support to request access.'
      }
      return ''
    }

    const emailInput = () => {
      if (props.isEmailHidden) {
        return
      }
      return (
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserEmail' }} className="col-sm-2 col-form-label">
            Email
          </CFormLabel>
          <CCol>
            <AsyncValidatedTextInput
              type="email"
              id="signupUserEmail"
              data-testid="signupUserEmail"
              placeholder="Email"
              value={props.value.email}
              required
              onValue={onEmailChange}
              disabled={props.disableEmail}
              validate={asyncValidateEmail}
              makeValidationMessage={makeEmailValidationMessage}
            />
            {/* Toggle-able helper text */}
            {!props.visionAccountConnected && (
              <CFormFeedback
                className="mt-1"
                onClick={props.openConnectVisionAccountModal}
                data-testid="connect-vision-account-feedback"
              >
                <span className="clickable">
                  <u>Connect your VISION account to IGNITE</u>
                </span>
              </CFormFeedback>
            )}
            <span className="subtle">
              E-mail addresses will never be shared or displayed to a small business or other IGNITE
              users.
            </span>
          </CCol>
        </CRow>
      )
    }

    return (
      <>
        {emailInput()}
        <CRow className="mb-3">
          <CFormLabel {...{ htmlFor: 'signupUserPassword' }} className="col-sm-2 col-form-label">
            Password
            <IconPopover
              content={
                <span>
                  Must be at least 6 characters, contain at least one capital and lowercase letter,
                  number, and special character (#?!@$%^&*-)
                </span>
              }
              placement={'right'}
              iconClassName={'fas fa-question-circle ms-2 text-primary'}
            />
          </CFormLabel>

          <CCol>
            <ValidatedCFormInput
              type="password"
              id="signupUserPassword"
              data-testid="signupUserPassword"
              placeholder="Password"
              value={props.value.password || ''}
              onChange={(password: string | undefined) => props.onChange({ password })}
              required
              validate={(v) => validatePassword(v, 6)}
              renderInvalidMessage={() =>
                'Must be at least 6 characters, contain at least one capital and lowercase letter, number, and special character (#?!@$%^&*-)'
              }
            />
          </CCol>
          <CRow></CRow>
        </CRow>
        <CRow className="mb-3">
          <CFormLabel
            {...{ htmlFor: 'signupUserConfirmPassword' }}
            className="col-sm-2 col-form-label"
          >
            Confirm Password
          </CFormLabel>

          <CCol>
            <ValidatedCFormInput
              type="password"
              id="signupUserConfirmPassword"
              data-testid="signupUserConfirmPassword"
              placeholder="Confirm Password"
              value={props.value.confirmPassword || ''}
              required
              onChange={(confirmPassword: string | undefined) =>
                props.onChange({ confirmPassword })
              }
              validate={(v) => validateValueMatch(props.value.password, v)}
              renderInvalidMessage={() => 'Passwords must match'}
            />
          </CCol>
        </CRow>
      </>
    )
  }),
)
