/**
 * NOTE
 *
 * Deprecated
 *
 * This is gettting added temporarily until we recreate the ServerBackedAutoComplete in VCR (https://app.clickup.com/t/86dvpmveu)
 * Once that work is complete, this entire file can be removed.
 */

import React, { useState, useEffect, useRef, ReactNode, PropsWithChildren } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import classnames from 'classnames'
import {
  PaginationData,
  PaginationParams,
  ServerPageData,
  Spinner,
} from '@mobilizeyourtech/vision-core-react'

import './server-backed-autocomplete.scss'

export interface AutoCompleteItem {
  id: string | number
  label: string
  shortLabel?: string
  sublabel?: string
  categoryLabel?: string
}

type InputProps = {
  id?: string
  label?: string
  required?: boolean
  requiredBreach?: boolean
  value?: any
  inputComponent?: any
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  className?: string
  maxLength?: number
  inputAriaLabel?: string
}

const Input = (props: PropsWithChildren<InputProps>) => {
  const renderLabel = () => {
    return (
      <div className="input-label-container">
        <div className="input-label d-flex justify-content-center align-items-center gap-1">
          <label>{props.label}</label>
          {props.required && <span className="subtle">(Required)</span>}
        </div>
      </div>
    )
  }

  return (
    <div id={props.id} className={`Input${props.className ? props.className : ''}`}>
      {!!props.label && renderLabel()}
      <div className="input-container">
        {props.inputComponent}
        {<div className="child-container">{props.children}</div>}
      </div>
    </div>
  )
}

export interface ServerBackedAutoCompleteProps<T extends AutoCompleteItem>
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    'onSelect'
  > {
  getDataFunction: (params: PaginationParams & { search?: string }) => Promise<ServerPageData<T>>
  onSelect: (selected: T | undefined) => void
  /**
   * @param {string} testId - ID used to target specific components in tests. This
   * attribute is added to several sub components within the ServerBackedAutoComplete:
   * - ${testId}-testid for the container div
   * - autocomplete-input${testId} for the input
   * - dropdown-item-loader${testId} for the loading spinner
   * - dropdown-item-error${testId} for the error loading message
   */
  testId?: string
  selected?: T
  placeholder?: string
  label?: string
  required?: boolean
  loadingBounceMs?: number // mainly just a test entry point
  searchDebounceMs?: number // default 500
  scrollDebounceMs?: number // default 200
  scrollLoadOffsetPx?: number // default 50
  clearInput?: boolean
  inputAriaLabel?: string
  ariaRequired?: boolean
  renderSelectItemContent?: (item: T) => ReactNode
  disabled?: boolean
}

// @deprecated - Remove and Replace usages once in VCR
export const ServerBackedAutoComplete = <T extends AutoCompleteItem>(
  props: ServerBackedAutoCompleteProps<T>,
) => {
  const [selectOptions, setSelectOptions] = useState<Array<T> | undefined>(undefined)
  const [lastPage, setLastPage] = useState<PaginationData | undefined>(undefined)

  const [search, setSearchValue] = useState<string | undefined>(undefined)

  const [optionsLoading, setOptionsLoading] = useState(false)
  const [isExtending, setIsExtending] = useState(false)
  const [loadingError, setLoadingError] = useState(undefined)

  const [isActive, setIsActive] = useState(false)
  const [requiredBreach, setRequiredBreach] = useState(false)

  const ref: any = useRef(null)
  const itemRefs = useRef<Array<HTMLDivElement>>([])
  const inputRef = useRef<HTMLInputElement>(null)

  const handleTargetOutside = (e: MouseEvent | FocusEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsActive(false)
      inputRef.current && inputRef.current.blur()
    }
  }

  const handleEscape = ({ key: kypress }: KeyboardEvent) => {
    if (kypress === 'Escape') {
      setIsActive(false)
      inputRef.current && inputRef.current.blur()
    }
  }

  useEffect(() => {
    !props.selected && setSearchValue(undefined)
  }, [props.selected])

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleTargetOutside)
      document.addEventListener('focusin', handleTargetOutside)
      document.addEventListener('keydown', handleEscape)
    }
    return () => {
      document.removeEventListener('mousedown', handleTargetOutside)
      document.removeEventListener('focusin', handleTargetOutside)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isActive])

  // Function to replace data (typically with a new search)
  const getData = (searchValue?: string) => {
    setOptionsLoading(true)
    return props
      .getDataFunction({
        page: 1,
        perPage: 10,
        search: searchValue === '' ? undefined : searchValue,
      })
      .then((page) => {
        setSelectOptions(page.data)
        setLastPage(page.pagination)
      })
      .catch((e) => {
        setLoadingError(e)
      })
      .finally(() => setTimeout(() => setOptionsLoading(false), props.loadingBounceMs || 200))
  }

  const debounceChange = useDebouncedCallback(
    // function
    (v) => getData(v),
    // delay in ms
    props.searchDebounceMs ? props.searchDebounceMs : 500,
  )

  // Function to extend data, typically on scroll with an existing search
  const extendData = async () => {
    try {
      setIsExtending(true)
      const page = await props.getDataFunction({
        // @ts-ignore
        page: lastPage.page + 1,
        perPage: 10,
        search: search === '' ? undefined : search,
      })

      setSelectOptions([...(selectOptions || []), ...page.data])
      setLastPage(page.pagination)
    } catch (e) {
      setLoadingError(e as any)
    } finally {
      setIsExtending(false)
    }
  }

  const onScrollBottom = () => {
    if (!isExtending && lastPage!.page !== lastPage!.pages) {
      extendData()
    }
  }

  const debouncedScroll = useDebouncedCallback(
    // function
    () => onScrollBottom(),
    // delay in ms
    props.scrollDebounceMs == null ? 200 : props.scrollDebounceMs,
  )

  const onScroll = (e: any) => {
    const offset = props.scrollLoadOffsetPx || 50
    if (e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - offset) {
      debouncedScroll()
    }
  }

  const makeItemLabel = (item?: T) => {
    if (!item) {
      return undefined
    }
    return item.shortLabel ? `${item.shortLabel} - ${item.label}` : item.label
  }

  const renderOptionsContent = () => {
    if (selectOptions == null) {
      return <span />
    } else if (optionsLoading) {
      return (
        <div
          data-testid="dropdown-item-loader"
          className="dropdown-item no-click dropdown-item-loader"
        >
          <Spinner className="dropdown-item-spinner" />
        </div>
      )
    }

    if (selectOptions!.length === 0) {
      return <a className="dropdown-item no-click subtle">No matches...</a>
    }

    const renderSelectItem = (item: T, idx: number) => {
      return (
        <div
          className="clickable autoselect-option dropdown-item"
          data-testid={`${item.id || idx}-testid`}
          key={item.id}
          tabIndex={-1}
          role="option"
          aria-selected={props.selected && item.id === props.selected.id}
          ref={(element) => {
            if (element) {
              itemRefs.current[idx] = element
            }
          }}
          onMouseDown={() => {
            setRequiredBreach(false)
            setSearchValue(item.label)
            props.onSelect && props.onSelect(item)
            setIsActive(false)
          }}
          onKeyDown={(e) => {
            let { key: kyprss } = e
            if (kyprss === 'Enter') {
              e.preventDefault()
              setRequiredBreach(false)
              setSearchValue(item.label)
              props.onSelect && props.onSelect(item)
              inputRef.current && inputRef.current.focus()
              setIsActive(false)
            } else if (kyprss === 'ArrowDown') {
              e.preventDefault()
              idx + 1 < itemRefs.current.length && itemRefs.current[idx + 1].focus()
            } else if (kyprss === 'ArrowUp' && idx === 0) {
              e.preventDefault()
              inputRef.current && inputRef.current.focus()
            } else if (kyprss === 'ArrowUp') {
              e.preventDefault()
              idx - 1 > -1 && itemRefs.current[idx - 1].focus()
            }
          }}
        >
          {props.renderSelectItemContent ? (
            props.renderSelectItemContent!(item)
          ) : (
            <>
              <p>{makeItemLabel(item)}</p>
              {item.sublabel && (
                <span className={'autoselect-option-sublabel subtle'}>{item.sublabel}</span>
              )}
            </>
          )}
        </div>
      )
    }

    let category: string | undefined

    return selectOptions.map((option, index) => {
      let divider

      if (option.categoryLabel && option.categoryLabel !== category) {
        divider = (
          <div
            className="categoryDivider p-3"
            data-testid="category-divider"
            title={option.categoryLabel}
          >
            {option.categoryLabel}
          </div>
        )
        category = option.categoryLabel
      }

      return (
        <>
          {divider}
          {renderSelectItem(option, index)}
        </>
      )
    })
  }

  const renderOptions = () => {
    if (!isActive) {
      return null
    }

    return (
      <div className="dropdown">
        <div
          data-testid={'dropdown-menu'}
          className={classnames('dropdown-menu', {
            active: isActive,
          })}
          onScroll={onScroll}
        >
          {renderOptionsContent()}

          {isExtending && !optionsLoading && (
            <div
              data-testid={`dropdown-item-loader${props.testId || ''}`}
              className="dropdown-item no-click dropdown-item-loader"
            >
              <Spinner className="dropdown-item-spinner" />
            </div>
          )}
          {!!loadingError && (
            <div
              data-testid={`dropdown-item-error${props.testId || ''}`}
              className="dropdown-item no-click dropdown-item-error"
            >
              <span>Error loading data...</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  const clearSelect = () => {
    setSearchValue(undefined)
    props.onSelect(undefined)
  }

  return (
    <>
      <div
        className={classnames('ServerBackedAutoComplete AutoComplete', props.className)}
        ref={ref}
      >
        <i
          className={classnames('fas fa-asterisk required-icon', {
            invalid: props.required && requiredBreach,
          })}
          data-testid={'test-required-icon'}
        />
        <Input
          label={props.label}
          required={props.required}
          requiredBreach={requiredBreach}
          inputComponent={
            <input
              disabled={props.disabled}
              type="text"
              aria-label={props.inputAriaLabel}
              aria-required={props.required || props.ariaRequired}
              role="listbox"
              data-testid={`autocomplete-input${props.testId || ''}`}
              className={classnames(
                'autoselect form-control',
                props.required && requiredBreach ? 'invalid is-invalid' : '',
              )}
              ref={inputRef}
              value={!props.clearInput ? makeItemLabel(props.selected) || search || '' : ''}
              placeholder={props.placeholder}
              onFocus={() => {
                setIsActive(true)
                clearSelect()
                getData('')
              }}
              onBlur={() => {
                if (props.required && !props.selected) {
                  setRequiredBreach(true)
                }
              }}
              onKeyDown={(e) => {
                const { key: kypress } = e
                if (kypress === 'ArrowDown') {
                  e.preventDefault()
                  itemRefs.current[0].focus()
                }
              }}
              onChange={({ target: { value } }) => {
                setSearchValue(value)
                props.onSelect(undefined)
                debounceChange(value)
              }}
            />
          }
        >
          {renderOptions()}
        </Input>
        {(props.selected || (selectOptions && selectOptions!.length === 0)) && !props.disabled && (
          <span
            data-testid="clear-input"
            onClick={() => clearSelect()}
            tabIndex={0}
            aria-label="Clear selection"
            onKeyDown={({ key: kypress }) => {
              if (kypress === 'Enter') {
                clearSelect()
              }
            }}
            className="clear-button"
          >
            <i className="fas fa-x" />
          </span>
        )}
      </div>
    </>
  )
}
