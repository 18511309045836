import { inject, observer } from 'mobx-react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApplicationStore, NotificationStore, UserStore } from '../../stores'
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CBadge,
  CButton,
  CCardHeader,
  CCardText,
  CTooltip,
} from '@coreui/react'

import { SuspensionBoundary } from '@mobilizeyourtech/vision-core-react'
import { GovUserData } from '../../stores/UserStore'
import { leftArrow } from '../../components/icons/leftArrow'
import { StartChatButton } from '../../components/custom'
import ReactHtmlParser from 'react-html-parser'
import { visionVisibilityIcon } from '../../assets/brand/vision_visibility_icon'
import { UserAvatar } from '../../components/user'
import { LinesOfEffortCard } from 'components/Organisms/EntityCards/LinesOfEffortCard/LinesOfEffortCard'
import { GovUserClassificationsCard } from 'components/Organisms/EntityCards/GovUserClassificationsCard/GovUserClassificationsCard'
import { GovUserConnectorChip } from 'components/Atoms/Chips/GovUserConnectorChip/GovUserConnectorChip'

type TProps = {
  userStore?: UserStore
  notificationStore?: NotificationStore
}

interface MatchParams {
  id: string
}

export const ViewIndividualGovUser = inject(
  ApplicationStore.names.userStore,
  ApplicationStore.names.notificationStore,
)(
  observer((props: TProps) => {
    const {
      params: { id },
    } = useRouteMatch<MatchParams>()
    const [govUser, setGovUser] = useState<GovUserData | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const navigate = useHistory()
    const goBack = () => {
      navigate.goBack()
    }

    useEffect(() => {
      setIsLoading(true)
      props.userStore
        ?.retrieveGovUserById(id, 'technologyTypes,govUserSetting,isActive,activeChatThreadsCount')
        .then((data) => setGovUser(data))
        .catch((err) => {
          setError(err)
          props.notificationStore?.setNotificationMessage(
            'Error retrieving gov user. Please try again.',
            'danger',
            3000,
          )
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false)
          }, 300)
        })
    }, [])

    const renderLocation = () => {
      let locationValues: Array<String | undefined> = []
      if (govUser?.govUserExtendedData) {
        const { city, stateOrProvince, country } = govUser?.govUserExtendedData
        locationValues = [city, stateOrProvince, country].filter(Boolean)
      }

      return (
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-4 col-lg-12 col-xl-4 category">
            <p>
              <i className="fa fa-location-arrow" />
              Location
            </p>
          </div>
          <div className="col-8 col-lg-12 col-xl-8 mt-0" data-testid="location">
            <p>{locationValues.join(', ')}</p>
          </div>
        </div>
      )
    }

    const renderYearsOfExperience = () => {
      if (!govUser?.govUserExtendedData.govEmployeeSinceYear) return null

      let yearsOfExperience = ''
      const currentYear = new Date().getFullYear()
      const experience = currentYear - govUser?.govUserExtendedData.govEmployeeSinceYear

      if (experience <= 5) {
        yearsOfExperience = '0-5'
      } else if (experience > 5 && experience <= 10) {
        yearsOfExperience = '6-10'
      } else if (experience > 10 && experience <= 15) {
        yearsOfExperience = '11-15'
      } else if (experience > 15 && experience <= 20) {
        yearsOfExperience = '16-20'
      } else if (experience > 20) {
        yearsOfExperience = '20+'
      }
      return (
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-4 col-lg-12 col-xl-4 category">
            <p>Years of Gov. Service</p>
          </div>
          <div className="col-8 col-lg-12 col-xl-8 mt-0" data-testid="years-of-experience">
            <p>{yearsOfExperience}</p>
          </div>
        </div>
      )
    }

    const UserInfoCard = () => {
      return (
        <CCard className="gov-user info-row-card py-3 px-4">
          <CCardHeader className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
            <h5 className="m-0">User Info</h5>
            <GovUserConnectorChip govUser={govUser} />
          </CCardHeader>
          <CCardBody className="d-flex row">
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-4 col-lg-12 col-xl-4 mt-0 category">
                <p>Name</p>
              </div>
              <div className="col-8 col-lg-12 col-xl-8 mt-0">
                <p>{govUser?.firstName + ' ' + govUser?.lastName}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-4 col-lg-12 col-xl-4 mt-0 category">
                <p>Title/Rank</p>
              </div>
              <div className="col-8 col-lg-12 col-xl-8 mt-0">
                <p>{govUser?.title}</p>
              </div>
            </div>
            {renderYearsOfExperience()}
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-4 col-lg-12 col-xl-4 mt-0 category">
                <p>Organization</p>
              </div>
              <div className="col-8 col-lg-12 col-xl-8 mt-0">
                <p>{govUser?.govUserExtendedData.govUserOrganization.name}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center">
              <div className="col-4 col-lg-12 col-xl-4 mt-0 category">
                <p>
                  <i className="fa fa-clock" />
                  Time Zone
                </p>
              </div>
              <div className="col-8 col-lg-12 col-xl-8 mt-0">
                <p>{govUser?.timezone.split('_').join(' ')}</p>
              </div>
            </div>
            {renderLocation()}
          </CCardBody>
        </CCard>
      )
    }

    const ProblemStatementCard = () => {
      return (
        <CCard className="gov-user info-row-card py-3 px-4">
          <CCardHeader component={'h5'}>Problem Statement & Role Description</CCardHeader>
          <CCardBody className="px-0">
            <div className={'ql-editor'}>
              {govUser?.govUserExtendedData.profileDetail &&
                ReactHtmlParser(govUser?.govUserExtendedData.profileDetail)}
            </div>
          </CCardBody>
        </CCard>
      )
    }

    const renderUser = (user: GovUserData) => (
      <CContainer className="IndividualGovUser">
        <div className={'py-4 d-flex justify-content-between'}>
          <div className="gov-user-header d-flex flex-row justify-content-start align-items-center gap-3">
            <CButton data-testid="back-btn" className="go-back" variant="ghost" onClick={goBack}>
              {leftArrow()}
            </CButton>
            <div className="gov-user-avatar d-flex justify-content-center align-items-center">
              <UserAvatar user={user} displayIsActive />
            </div>
            <div className="gov-user-title d-flex justify-content-center align-items-center">
              <h4>
                {user.firstName} {user.lastName}{' '}
              </h4>
              {user.govUserExtendedData.visionUserId && (
                <CTooltip content={'Verified VISION user'}>
                  <div className="d-flex justify-content-center align-items-center ms-2 mb-1 icon-container">
                    {visionVisibilityIcon()}
                  </div>
                </CTooltip>
              )}
            </div>
          </div>
          <div>{govUser && <StartChatButton target={govUser} />}</div>
        </div>

        <div className="d-flex flex-column gap-3 p-3">
          <CRow xs={{ gutterY: '1rem' }}>
            <CCol lg={6}>
              <UserInfoCard />
            </CCol>
            <CCol lg={6}>
              <GovUserClassificationsCard
                govUserClassifications={user.govUserExtendedData.govUserClassifications}
                className="gov-user info-row-card py-3 px-4"
              />
            </CCol>
          </CRow>
          <CRow
            xs={{ gutterY: '1rem' }}
            className="gov-user-column d-flex row justify-content-start"
          >
            <CCol xs={12} className="gov-user-column">
              <ProblemStatementCard />
            </CCol>
            <CCol xs={12} className="gov-user-column">
              <LinesOfEffortCard
                linesOfEffort={user.govUserExtendedData.linesOfEffort}
                className="gov-user loe-card py-3 px-4"
                data-testid="loe-card"
              />
            </CCol>
            <CCol xs={12} className="gov-user-column">
              <CCard className="gov-user info-row-card py-3 px-4">
                <CCardHeader component="h5">Technology Types</CCardHeader>
                <CCardBody>
                  <CRow className="justify-content-md-start pt-2">
                    {user.technologyTypes.map((tt) => {
                      return (
                        <CCol key={tt.name} className={'col-md-auto px-1 mb-2'}>
                          <CBadge color="primary" className="text-wrap text-md-start py-2">
                            {tt.name}
                          </CBadge>
                        </CCol>
                      )
                    })}
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </CContainer>
    )

    const renderError = () => (
      <CContainer
        data-testid="error-container"
        className="error-container w-100 d-flex align-items-center justify-content-center"
      >
        <CCard className="gov-user error justify-content-center align-items-center">
          <CCardBody className="d-flex justify-content-center align-items-center">
            <CCardText>Error retrieving gov user. Please try again.</CCardText>
          </CCardBody>
        </CCard>
      </CContainer>
    )

    return (
      <section className="ViewIndividualGovUser">
        <SuspensionBoundary isLoading={isLoading}>
          {!error && govUser ? renderUser(govUser) : renderError()}
        </SuspensionBoundary>
      </section>
    )
  }),
)
